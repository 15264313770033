import { proxy } from 'valtio';
/** 指標傳參數 */
export const eurexIndicatorsValueStorage = proxy({
    // Volume
    highestVolume5: NaN,
    highestVolume20: NaN,
    highestVolume60: NaN,
    lowestVolume5: NaN,
    lowestVolume20: NaN,
    lowestVolume60: NaN,
    averageVolume5: NaN,
    averageVolume20: NaN,
    averageVolume60: NaN,
    // OHLC
    dayOpen: NaN,
    dayHigh: NaN,
    dayLow: NaN,
    dayClose: NaN,
    dayVolume: NaN,
    highestHigh5: NaN,
    highestHigh20: NaN,
    highestHigh60: NaN,
    lowestLow5: NaN,
    lowestLow20: NaN,
    lowestLow60: NaN,
    rsv5: NaN,
    rsv20: NaN,
    rsv60: NaN,
    // Mov avg
    ma5: NaN,
    ma20: NaN,
    ma60: NaN,
    ma120: NaN,
    // Mov avg slope
    slope5: NaN,
    slope20: NaN,
    slope60: NaN,
    slope120: NaN,
    // bias
    bias5: NaN,
    bias20: NaN,
    bias60: NaN,
    bias120: NaN,
    // offset value of mov avg
    offsetValue5: NaN,
    offsetValue20: NaN,
    offsetValue60: NaN,
    offsetValue120: NaN,
    // power
    powerTrend: NaN,
    powerMacd: NaN,
    powerDMI: NaN,
    // amp
    currentAmp: NaN,
    averageAmp05: NaN,
    averageAmp20: NaN,
    averageAmp60: NaN,
    averageAmp120: NaN,
    // interval
    internalType: '',
});
export const resetIndicatorsValue = () => {
    eurexIndicatorsValueStorage.highestVolume5 = NaN;
    eurexIndicatorsValueStorage.lowestVolume5 = NaN;
    eurexIndicatorsValueStorage.highestVolume20 = NaN;
    eurexIndicatorsValueStorage.lowestVolume20 = NaN;
    eurexIndicatorsValueStorage.highestVolume60 = NaN;
    eurexIndicatorsValueStorage.lowestVolume60 = NaN;
    eurexIndicatorsValueStorage.averageVolume5 = NaN;
    eurexIndicatorsValueStorage.averageVolume20 = NaN;
    eurexIndicatorsValueStorage.averageVolume60 = NaN;
    eurexIndicatorsValueStorage.dayOpen = NaN;
    eurexIndicatorsValueStorage.dayHigh = NaN;
    eurexIndicatorsValueStorage.dayLow = NaN;
    eurexIndicatorsValueStorage.dayClose = NaN;
    eurexIndicatorsValueStorage.dayVolume = NaN;
    eurexIndicatorsValueStorage.highestHigh60 = NaN;
    eurexIndicatorsValueStorage.highestHigh20 = NaN;
    eurexIndicatorsValueStorage.highestHigh5 = NaN;
    eurexIndicatorsValueStorage.lowestLow60 = NaN;
    eurexIndicatorsValueStorage.lowestLow20 = NaN;
    eurexIndicatorsValueStorage.lowestLow5 = NaN;
    eurexIndicatorsValueStorage.rsv60 = NaN;
    eurexIndicatorsValueStorage.rsv20 = NaN;
    eurexIndicatorsValueStorage.rsv5 = NaN;
    eurexIndicatorsValueStorage.ma5 = NaN;
    eurexIndicatorsValueStorage.ma20 = NaN;
    eurexIndicatorsValueStorage.ma60 = NaN;
    eurexIndicatorsValueStorage.ma120 = NaN;
    eurexIndicatorsValueStorage.slope5 = NaN;
    eurexIndicatorsValueStorage.slope20 = NaN;
    eurexIndicatorsValueStorage.slope60 = NaN;
    eurexIndicatorsValueStorage.slope120 = NaN;
    eurexIndicatorsValueStorage.bias5 = NaN;
    eurexIndicatorsValueStorage.bias20 = NaN;
    eurexIndicatorsValueStorage.bias60 = NaN;
    eurexIndicatorsValueStorage.bias120 = NaN;
    eurexIndicatorsValueStorage.offsetValue5 = NaN;
    eurexIndicatorsValueStorage.offsetValue20 = NaN;
    eurexIndicatorsValueStorage.offsetValue60 = NaN;
    eurexIndicatorsValueStorage.offsetValue120 = NaN;
};
