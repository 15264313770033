import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const timeValueForTVChart2 = createIndicator({
    displayName: '價平時間價值',
    id: 'TIMEVALUE',
    enabledOn(symbol, data, channel) {
        return ((data?.type === channel.tw_futures && symbol.includes('-')) ||
            (symbol.includes('TX') && symbol.length == 10));
    },
    constructorScope: {
        init(context, inputCallback) {
            const ticker = this.PineJS.Std.ticker(this._context)
                .toString()
                .replace(/[^\w-]/g, '');
            const symbol = ticker + '#iv';
            this._context.new_sym(symbol, this.PineJS.Std.period(this._context));
        },
        main(context, inputCallback) {
            context.select_sym(1);
            let o = this.PineJS.Std.open(context); // time value
            if (o === 0) {
                o = NaN;
            }
            return [o]; //
        },
    },
    metainfo: {
        _metainfoVersion: 40,
        scriptIdPart: '',
        is_hidden_study: false,
        is_price_study: false,
        isCustomIndicator: true,
        plots: [{ id: 'plot_0', type: 'line' }],
        defaults: {
            styles: {
                plot_0: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 3,
                    // Plot type is Line
                    plottype: 8,
                    trackPrice: false,
                    transparency: 50,
                    color: '#258e8e',
                },
            },
            // Precision is 0 digit, only integer
            precision: 0,
            inputs: {},
        },
        styles: {
            plot_0: {
                title: '價平時間價值',
                histogramBase: 0,
            },
        },
        inputs: [],
    },
});
