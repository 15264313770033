import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const estimatedVolumeForTVChart2 = createIndicator({
    displayName: '預估成交量',
    id: 'estimatedVolume',
    enabledOn(symbol, data, channel) {
        return ((data?.type === channel.tw_futures && symbol.includes('-')) ||
            (symbol.includes('TX') && symbol.length == 10) ||
            (symbol.length == 4 && parseInt(symbol) > 0) ||
            symbol == 'TSEA' ||
            symbol == 'OTCA');
    },
    constructorScope: {
        init(context, inputCallback) {
            const ticker = this.PineJS.Std.ticker(context)
                .toString()
                .replace(/[^\w-]/g, '');
            const symbol = ticker + '#estimated_volume';
            context.new_sym(symbol, this.PineJS.Std.period(this._context));
        },
        main(context, inputCallback) {
            context.select_sym(1);
            // You can use following built-in functions in PineJS.Std object:
            // 	open, high, low, close
            //	hl2, hlc3, ohlc4
            const o = this.PineJS.Std.open(this._context);
            return [o];
        },
    },
    metainfo: {
        _metainfoVersion: 40,
        scriptIdPart: '',
        is_hidden_study: false,
        is_price_study: false,
        isCustomIndicator: true,
        plots: [{ id: 'plot_0', type: 'line' }],
        defaults: {
            styles: {
                plot_0: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 2,
                    // Plot type is Line
                    plottype: 2,
                    trackPrice: false,
                    transparency: 30,
                    color: '#ff0000',
                },
            },
            // Precision is 0 digit, only integer
            precision: 0,
            inputs: {},
        },
        styles: {
            plot_0: {
                title: '預估成交量',
                histogramBase: 0,
            },
        },
        inputs: [],
    },
});
/* eslint-disable no-var */
export function bs0(pineJS) {
    return {
        name: 'BS0',
        metainfo: {
            _metainfoVersion: 40,
            id: 'BS0@tv-basicstudies-1',
            scriptIdPart: '',
            name: 'BS0',
            description: 'BS0',
            shortDescription: '主散方向',
            is_hidden_study: false,
            is_price_study: false,
            isCustomIndicator: true,
            plots: [
                { id: 'plot_0', type: 'line' },
                { id: 'plot_1', type: 'line' },
                { id: 'plot_2', type: 'line' },
            ],
            defaults: {
                styles: {
                    plot_0: {
                        linestyle: 0,
                        visible: true,
                        linewidth: 2,
                        // Plot type is Area
                        plottype: 4,
                        trackPrice: false,
                        transparency: 60,
                        color: '#0000ff',
                    },
                    plot_1: {
                        linestyle: 0,
                        visible: true,
                        linewidth: 2,
                        // Plot type is Area
                        plottype: 4,
                        trackPrice: false,
                        transparency: 60,
                        color: '#007f00',
                    },
                    plot_2: {
                        linestyle: 0,
                        visible: true,
                        linewidth: 2,
                        // Plot type is Line
                        plottype: 2,
                        trackPrice: false,
                        transparency: 30,
                        color: '#ff0000',
                    },
                },
                // Precision is 0 digit, only integer
                precision: 0,
                inputs: {},
            },
            styles: {
                plot_0: {
                    title: '大戶',
                    histogramBase: 0,
                },
                plot_1: {
                    title: '散戶',
                    histogramBase: 0,
                },
                plot_2: {
                    title: '總和',
                    histogramBase: 0,
                },
            },
            inputs: [],
        },
        constructor: function () {
            /** @ts-expect-error 採用先前語法，暫不處理 */
            this.init = function (context, inputCallback) {
                this._context = context;
                this._input = inputCallback;
                var ticker = pineJS.Std.ticker(this._context)
                    .toString()
                    .replace(/[^\w-]/g, '');
                var symbol = ticker + '#bs_indicator_acc';
                this._context.new_sym(symbol, pineJS.Std.period(this._context));
            };
            /** @ts-expect-error 採用先前語法，暫不處理 */
            this.main = function (context, inputCallback) {
                this._context = context;
                this._input = inputCallback;
                this._context.select_sym(1);
                // You can use following built-in functions in PineJS.Std object:
                // 	open, high, low, close
                //	hl2, hlc3, ohlc4
                var h = pineJS.Std.high(this._context);
                var l = pineJS.Std.low(this._context);
                var o = pineJS.Std.open(this._context);
                return [h, l, o];
            };
        },
    };
}
