import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const bs1ForTVChart2 = createIndicator({
    id: 'BS1',
    displayName: '攻擊力道',
    enabledOn(symbol, data, channel) {
        return ((data?.type === channel.tw_futures && symbol.includes('-')) ||
            (symbol.includes('TX') && symbol.length == 10) ||
            (symbol.length == 4 && parseInt(symbol) > 0));
    },
    constructorScope: {
        init(context, inputCallback) {
            const ticker = this.PineJS.Std.ticker(context)
                .toString()
                .replace(/[^\w-]/g, '');
            const symbol = ticker + '#bs_indicator';
            context.new_sym(symbol, this.PineJS.Std.period(this._context));
        },
        main(context, inputCallback) {
            /*
              open: 'net_buy',
              high: 'net_strong_buy',
              low: 'net_weak_buy',
              close: 'buy_volume',
              volume:'sell_volume',
            */
            context.select_sym(1);
            const c = this.PineJS.Std.close(this._context);
            const v = this.PineJS.Std.volume(this._context);
            return [c, v];
        },
    },
    metainfo: {
        _metainfoVersion: 40,
        scriptIdPart: '',
        is_hidden_study: false,
        is_price_study: false,
        isCustomIndicator: true,
        plots: [
            { id: 'plot_0', type: 'line' },
            { id: 'plot_1', type: 'line' },
        ],
        defaults: {
            styles: {
                plot_0: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 2,
                    // Plot type is Column
                    plottype: 5,
                    trackPrice: false,
                    transparency: 50,
                    color: '#ff000b',
                },
                plot_1: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 2,
                    // Plot type is Column
                    plottype: 5,
                    trackPrice: false,
                    transparency: 50,
                    color: '#348e4c',
                },
            },
            // Precision is 0 digit, only integer
            precision: 0,
            inputs: {},
        },
        styles: {
            plot_0: {
                title: '多方攻擊',
                histogramBase: 0,
            },
            plot_1: {
                title: '空方攻擊',
                histogramBase: 0,
            },
        },
        inputs: [],
    },
});
