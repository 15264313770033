import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const subtractForTVChart2 = createIndicator({
    displayName: 'Subtract (A-B)',
    id: 'Subtract (A-B)',
    metainfo: {
        _metainfoVersion: 27,
        isTVScript: !1,
        isTVScriptStub: !1,
        is_hidden_study: !1,
        defaults: {
            styles: {
                plot_0: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 40,
                    visible: !0,
                    color: '#800080',
                },
            },
            precision: 0,
            inputs: {
                in_0: 'TSEA',
            },
        },
        plots: [
            {
                id: 'plot_0',
                type: 'line',
            },
        ],
        styles: {
            plot_0: {
                title: 'Plot',
                histogramBase: 0,
                joinPoints: !1,
            },
        },
        is_price_study: !1,
        inputs: [
            {
                id: 'in_0',
                name: '比較商品(減數)',
                defval: 'TSEA',
                type: 'symbol',
            },
        ],
        scriptIdPart: '',
    },
    constructorScope: {
        init(context, input) {
            context.new_sym(input(0), this.PineJS.Std.period(context));
        },
        main(context, inputCallback) {
            this._context = context;
            this._input = inputCallback;
            const i = this._context.new_unlimited_var(this._context.symbol.time);
            this._input(0);
            this.PineJS.Std.period(this._context);
            const o = this.PineJS.Std.close(this._context);
            const r = this._input(1);
            this._context.select_sym(1);
            const s = this._context.new_unlimited_var(this._context.symbol.time);
            const a = this.PineJS.Std.close(this._context);
            const l = this._context.new_unlimited_var(a);
            this._context.select_sym(0);
            const c = l.adopt(s, i, 0); // not really sure about this
            const u = this._context.new_var(o);
            const h = this._context.new_var(c);
            // preload 1000 kbar (prevent misalignment)
            u.get(1000);
            h.get(1000);
            return u - h;
        },
    },
});
