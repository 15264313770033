import dayjs from 'dayjs';
import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const extremeVolume = createIndicator({
    displayName: '爆量訊號',
    id: 'EV',
    enabledOn(symbol, data, channel) {
        return ((data?.type === channel.tw_futures && symbol.includes('-')) ||
            (symbol.includes('TX') && symbol.length == 10) ||
            data?.type === channel.tw_stocks_all);
    },
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const o = this.PineJS.Std.open(this._context);
            const h = this.PineJS.Std.high(this._context);
            const l = this.PineJS.Std.low(this._context);
            const c = this.PineJS.Std.close(this._context);
            const v = this.PineJS.Std.volume(this._context);
            const datetime = dayjs(this.PineJS.Std.time(this._context));
            const symbol = this.PineJS.Std.ticker(this._context);
            const isAmSession = datetime.hour() >= 8 && datetime.hour() <= 13;
            const period = this.PineJS.Std.period(this._context);
            /*
              跳爆量警示：
              主圖K時間頻率開：
              1（default)；3；5
              跳爆量警示：
              一分K：
              日盤：1500&2000
              夜盤：550&800（下調）
              三分K：
              日盤：3750&5000（下調）
              夜盤：1150&1800（下調）
              五分K：
              日盤：5250&7500（下調）
              夜盤：1600&2300（下調）
            */
            const isUpBar = c >= o;
            let threshold = 999999999;
            if (symbol.includes('TX-')) {
                if (period === '1') {
                    threshold = isAmSession ? 1500 : 550;
                }
                else if (period === '3') {
                    threshold = isAmSession ? 3750 : 1150;
                }
                else if (period === '5') {
                    threshold = isAmSession ? 5250 : 1600;
                }
            }
            let volIndicator = NaN;
            let verticalLine = NaN;
            if (v > threshold) {
                if (isUpBar) {
                    volIndicator = v * 1.5;
                    verticalLine = v * 100;
                }
                else {
                    volIndicator = v * 1.5;
                    verticalLine = v * 100;
                }
            }
            return [NaN, NaN, verticalLine];
        },
    },
    metainfo: {
        _metainfoVersion: 27,
        scriptIdPart: '',
        is_hidden_study: false,
        is_price_study: true,
        isCustomIndicator: true,
        plots: [
            { id: 'plot_0', type: 'chars' },
            { id: 'plot_1', type: 'chars' },
            {
                id: 'plot_vline',
                type: 'histogram',
            },
        ],
        defaults: {
            styles: {
                plot_0: {
                    color: '#ff0000',
                    textColor: '#ff0000',
                    transparency: 20,
                    visible: true,
                },
                plot_1: {
                    color: '#00ff00',
                    textColor: '#00ff00',
                    transparency: 20,
                    visible: true,
                },
                plot_vline: {
                    linestyle: 1,
                    visible: true,
                    linewidth: 1,
                    plottype: 1,
                    trackPrice: false,
                    transparency: 40,
                    color: '#cccc00',
                },
            },
            // Precision is 0 digit, only integer
            precision: 0,
            inputs: {},
        },
        styles: {
            plot_0: {
                isHidden: false,
                location: 'BelowBar',
                char: '*',
                size: 'small',
                text: '',
            },
            plot_1: {
                isHidden: false,
                location: 'AboveBar',
                char: '*',
                size: 'small',
                text: '',
            },
            plot_vline: {
                title: '垂直線',
                histogramBase: 0,
            },
        },
        inputs: [],
    },
});
